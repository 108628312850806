// 企业应用鉴权时回调的地址
export const appsRedirectUri = {
  wechat_report: 'https://br.ijuzhong.com/wechatReport',
  wechat_nirvana: 'https://br.ijuzhong.com/wechatNirvana',
  wechat_report_person: 'https://br.ijuzhong.com/wechatReportPerson',
  wechat_activity: 'https://br.ijuzhong.com/wechatActivity',
  wechat_cz_activity: 'https://br.ijuzhong.com/wechatCzActivity',
  wechat_pk: 'https://br.ijuzhong.com/wechatPk',
}

export const testInfo = {
  // username: '何斯琼',
  // dept_id: 2481,
  // userid: 9627902,

  // shr_dept_id: 'mrEAAABEPGvM567U',
  // shr_auth_real_id: 9622761,

  // shr_dept_id: 'w5dR2bdARMWd7Ku7X+e9MsznrtQ=',
  // shr_auth_real_id: 9600136,

  // shr_dept_id: 'mrEAAAEeL5XM567U',
  // shr_auth_real_id: 9600256,

  // shr_dept_id: 'mrEAAAEeQEfM567U',
  // shr_auth_real_id: 9603835,

  shr_dept_id: 'mrEAAAEeRiLM567U',
  shr_auth_real_id: 9627774,

  bi_access_token: '7dff673541033daeab75b879a5b05319',
  bimar_group_id: '10',
  username: '黄静',
  dept_id: 28,
  // userid: 9603921,
  userid: 9624779,

  // username: '区域',
  // dept_id : 51,   
  // userid : 15828,

  // username: '莫勇',
  // dept_id: 145,
  // userid: 'moyong',

  // username: '张洋', // 个人的
  // dept_id: 227,
  // userid: '15399',

  // username: '黄奇', // 个人的
  // dept_id: 45,
  // userid: '14994',

  // username: '钟爱国', // 分公司经理
  // dept_id: 50,
  // userid: '9604649',
}
