import { createApp } from 'vue'
import { ActionSheet, Toast, NavBar } from 'vant';
import App from './App.vue'
import router from './router'
import store from './store'
import "@/styles/index.less"
import "hj.css"
import "@/iconfont/iconfont.css"
import "@/iconfont/iconfont.eot"
import "@/iconfont/iconfont.svg"
import "@/iconfont/iconfont.woff"
import "@/iconfont/iconfont.woff2"
import "@/iconfont/iconfont.js"
import initFontSize from "@/utils/rem"
if (window.location.href.indexOf("/pc") == -1) {
  initFontSize()
} else {
  document.documentElement.style.fontSize = "14px"
}
createApp(App).use(store).use(router).use(ActionSheet).use(Toast).use(NavBar).mount('#app')
