import { MutationTree } from "vuex";
import { AppState } from "./state";
import { AppMutationTypes } from "./types";
import * as API from "@/api/modules/app";

export type AppMutations<S = AppState> = {
  [AppMutationTypes.REPORT_YEAR_LIST](state: S): void
  InitAIData(state: S): void
  setAIData(state: S, data: { key: keyof ReportConfig, val: any }): void
}

export const appMutations: MutationTree<AppState> & AppMutations = {
  [AppMutationTypes.REPORT_YEAR_LIST](state: AppState) {
    API.apiReportYearList()
      .then(res => {
        if (res.status === 200) {
          state.reportYearList = res.data_list
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  InitAIData(state: AppState) {
    state.reportConfig = {
      totalStatusType: "",
      totalStatusYear: "",
      channelGroup: "",
      originStatusYear: "",
    }
  },
  setAIData(state: AppState, { key, val }) {
    state.reportConfig![key] = val
  }
}