import { GetterTree, Getter } from "vuex";
import { RootState } from "@/store";
import { AppState } from "./state";
import { AppGetterTypes } from "./types";

export type AppGetters<S = AppState, R = RootState> = {
  [AppGetterTypes.G_REPORT_YEAR_LIST](state: S): Action[]
}

export const appGetters: GetterTree<AppState, RootState> & AppGetters = {
  [AppGetterTypes.G_REPORT_YEAR_LIST](state: AppState) {
    const actions: Action[] = []
    state.reportYearList.forEach(el => {
      actions.push({
        text: el + "",
        className: "h-selecter",
      })
    });
    return actions
  }
}
