export enum AppGetterTypes {
  G_REPORT_YEAR_LIST = "G_REPORT_YEAR_LIST",
}

export enum AppMutationTypes {
  SET_IDENTITY = "SET_IDENTITY",
  REPORT_YEAR_LIST = "REPORT_YEAR_LIST",
}

export enum AppActionTypes {
  ACTION_SET_IDENTITY = "ACTION_SET_IDENTITY",
  ACTION_REPORT_YEAR_LIST = "ACTION_REPORT_YEAR_LIST",
}
