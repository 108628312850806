import { API_ROUTER_CONFIG } from "@/api/config";
import { ajax } from '@/utils/ajax';



export const apiReportYearList: ReportYearListModel = () => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportYearListModel>>({
      url: API_ROUTER_CONFIG.reportYearList,
      method: "get",
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const apiReportDataYear: ReportDataYearModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataYearModel>>({
      url: API_ROUTER_CONFIG.reportDataYear,
      data: data,
      method: "post"
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const apiReportDataYearMon: ReportDataYearMonModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataYearMonModel>>({
      url: API_ROUTER_CONFIG.reportDataYearMon,
      data: data,
      method: "post"
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const apiReportDataChannel: ReportDataChannelModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataChannelModel>>({
      url: API_ROUTER_CONFIG.reportDataChannel,
      data: data,
      method: "post"
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const apiGetDecorationMultipleYear: GetDecorationMultipleYearModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetDecorationMultipleYearModel>>({
      url: API_ROUTER_CONFIG.getDecorationMultipleYear,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiGetOtherMultipleYear: GetOtherMultipleYearModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetOtherMultipleYearModel>>({
      url: API_ROUTER_CONFIG.getOtherMultipleYear,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiGetSurveyStatistics: GetSurveyStatisticsModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetSurveyStatisticsModel>>({
      url: API_ROUTER_CONFIG.getSurveyStatistics,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiReportDataChannelList: ReportDataChannelListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataChannelListModel>>({
      url: API_ROUTER_CONFIG.reportDataChannelList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiReportDataChannelYearMon: ReportDataChannelYearMonModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataChannelYearMonModel>>({
      url: API_ROUTER_CONFIG.reportDataChannelYearMon,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiReportDataList: ReportDataListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataListModel>>({
      url: API_ROUTER_CONFIG.reportDataList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiReportDataRegionYearMon: ReportDataRegionYearMonModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportDataRegionYearMonModel>>({
      url: API_ROUTER_CONFIG.reportDataRegionYearMon,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 企业微信授权回调返回信息（PK榜单）
export const apiWxEnterpriseMessageNirvana: WxEnterpriseMessageNirvanaModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<WxEnterpriseMessageNirvanaModel>>({
      url: API_ROUTER_CONFIG.wxEnterpriseMessageNirvana,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiGetNirvanaConfig: GetNirvanaConfigModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetNirvanaConfigModel>>({
      url: API_ROUTER_CONFIG.getNirvanaConfig,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiGetNirvanaList: GetNirvanaListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetNirvanaListModel>>({
      url: API_ROUTER_CONFIG.getNirvanaList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiGetNirvanaNew: GetNirvanaNewModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetNirvanaNewModel>>({
      url: API_ROUTER_CONFIG.getNirvanaNew,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// PK榜单差距页面
export const apiGetNirvanaDisparity: GetNirvanaDisparityModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<GetNirvanaDisparityModel>>({
      url: API_ROUTER_CONFIG.getNirvanaDisparity,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取员工分布人数报表
export const apiReportShrUserNumber: ReportShrUserNumberModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportShrUserNumberModel>>({
      url: API_ROUTER_CONFIG.reportShrUserNumber,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取学历分布人数报表
export const apiReportShrUserDegree: ReportShrUserDegreeModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportShrUserDegreeModel>>({
      url: API_ROUTER_CONFIG.reportShrUserDegree,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取部门分布人数报表
export const apiReportShrUserDepart: ReportShrUserDepartModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportShrUserDepartModel>>({
      url: API_ROUTER_CONFIG.reportShrUserDepart,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取年份列表
export const apiReportPersonYearList: ReportPersonYearListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportPersonYearListModel>>({
      url: API_ROUTER_CONFIG.reportPersonYearList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiBiPeUserOrg = () => {
  return new Promise((resolve, reject) => {
    ajax({
      url: API_ROUTER_CONFIG.biPeUserOrg,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
export const apiBiPeUserList = (data: any) => {
  return new Promise((resolve, reject) => {
    ajax({
      url: API_ROUTER_CONFIG.biPeUserList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};


// 获取分公司列表
export const apiReportPersonOrgList: ReportPersonOrgListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportPersonOrgListModel>>({
      url: API_ROUTER_CONFIG.reportPersonOrgList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取数据列表
export const apiReportPersonDataList: ReportPersonDataListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportPersonDataListModel>>({
      url: API_ROUTER_CONFIG.reportPersonDataList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取明细列表年月下拉
export const apiReportShrUserYearMon: ReportShrUserYearMonModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportShrUserYearMonModel>>({
      url: API_ROUTER_CONFIG.reportShrUserYearMon,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取区域公司部门明显列表
export const apiReportShrUserDepartMon: ReportShrUserDepartMonModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ReportShrUserDepartMonModel>>({
      url: API_ROUTER_CONFIG.reportShrUserDepartMon,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取活动选项列表
export const apiActivityOptionList: ActivityOptionListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ActivityOptionListModel>>({
      url: API_ROUTER_CONFIG.activityOptionList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取活动数据列表
export const apiActivityDataList: ActivityDataListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ActivityDataListModel>>({
      url: API_ROUTER_CONFIG.activityDataList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取活动选项列表-常株
export const apiCzActivityOptionList: ActivityOptionListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ActivityOptionListModel>>({
      url: API_ROUTER_CONFIG.czActivityOptionList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// 获取活动数据列表-常株
export const apiCzActivityDataList: ActivityDataListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<ActivityDataListModel>>({
      url: API_ROUTER_CONFIG.czActivityDataList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// PK榜单-首页判断
export const apiPkTypeList: PkTypeListModel = (data) => {
  return new Promise((resolve, reject) => {
    ajax<ReturnType<PkTypeListModel>>({
      url: API_ROUTER_CONFIG.pkTypeList,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// PK榜单-积分排行
export const apiGetPkResult = (data: any) => {
  return new Promise((resolve, reject) => {
    ajax({
      url: API_ROUTER_CONFIG.getPkResult,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
// PK榜单-相邻差异
export const apiGetPkDisparity = (data: any) => {
  return new Promise((resolve, reject) => {
    ajax({
      url: API_ROUTER_CONFIG.getPkDisparity,
      data: data,
      method: 'post',
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};
