import { createRouter, createWebHistory } from "vue-router"
import mobileRoutes from "./mobileRoutes"
import pcRoutes from "./pcRoutes"
import { init } from "@/utils/app";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: mobileRoutes.concat(pcRoutes)
})

router.beforeEach((to, from, next) => {
  window.localStorage.setItem("currUrl", window.location.href);
  if (to.meta.title) {
    document.title = to.meta.title as string || "居众集团报表系统"
  }
  init(to).then((res: any) => {
    if (res.status == 200)
      next();
  });
})

export default router
