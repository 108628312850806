export const API_ROUTER_CONFIG = {
  wxEnterpriseMessageReport: '/wechat/wx_enterprise_message_report',
  wxEnterpriseOauthUrlReport: '/wechat/wx_enterprise_oauth_url_report',
  reportYearList: '/report/report_year_list',
  reportDataYear: '/report/report_data_year',
  reportDataYearMon: '/report/report_data_year_mon',
  reportDataChannel: '/report/report_data_channel',
  getDecorationMultipleYear: '/report/get_decoration_multiple_year',
  getOtherMultipleYear: '/report/get_other_multiple_year',
  getSurveyStatistics: '/report/get_survey_statistics',
  reportDataChannelList: '/report/report_data_channel_list',
  reportDataChannelYearMon: '/report/report_data_channel_year_mon',
  reportDataList: '/report/report_data_list',
  reportDataRegionYearMon: '/report/report_data_region_year_mon',
  wxEnterpriseMessageNirvana: '/wechat/wx_enterprise_message_nirvana', // 企业微信授权回调返回信息（PK榜单）
  getNirvanaConfig: '/report/get_nirvana_config',
  getNirvanaList: '/report/get_nirvana_list',
  getNirvanaNew: '/report/get_nirvana_new',
  getNirvanaDisparity: '/report/get_nirvana_disparity', // PK榜单差距页面
  reportShrUserNumber: '/report/report_shr_user_number', // 获取员工分布人数报表
  reportShrUserDegree: '/report/report_shr_user_degree', // 获取学历分布人数报表
  reportShrUserDepart: '/report/report_shr_user_depart', // 获取部门分布人数报表
  wxEnterpriseMessageReportPerson: '/wechat/wx_enterprise_message_report_person', // 企业微信授权回调返回信息（个人报表）
  reportPersonYearList: '/report/report_person_year_list', // 获取年份列表
  biPeUserOrg: '/report/bi-pe-user-org', // 组织架构
  biPeUserList: '/report/bi-pe-user-list', // 部门用户数据
  reportPersonOrgList: '/report/report_person_org_list', // 获取分公司列表
  reportPersonDataList: '/report/report_person_data_list', // 获取数据列表
  reportShrUserYearMon: '/report/report_shr_user_year_mon', // 获取明细列表年月下拉
  reportShrUserDepartMon: '/report/report_shr_user_depart_mon', // 获取区域公司部门明显列表
  activityOptionList: '/report/activity_option_list', // 获取活动选项列表
  activityDataList: '/report/activity_data_list', // 获取活动数据列表
  czActivityOptionList: '/report/cz_activity_option_list', // 获取活动选项列表-常株
  czActivityDataList: '/report/cz_activity_data_list', // 获取活动数据列表-常株
  pkTypeList: '/report/pk_type_list', // PK榜单-首页判断
  getPkResult: '/report/get_pk_result', // PK榜单-积分排行
  getPkDisparity: '/report/get_pk_disparity', // PK榜单-相邻差异
}