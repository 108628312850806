import {
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module
} from 'vuex';

import { RootState } from "@/store";
import { state } from "./state";
import type { AppState } from "./state";
import { appGetters, AppGetters } from "./getters"
import { appMutations, AppMutations } from "./mutations";
import { appActions, AppActions } from "./actions";

export { AppState };


export type AppStore<S = AppState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
  & {
    getters: AppGetters
  }
  & {
    commit<K extends keyof AppMutations, P extends Parameters<AppMutations[K]>[1]>(
      key: K,
      payload: P,
      options?: CommitOptions
    ): ReturnType<AppMutations[K]>
  }
  & {
    dispatch<K extends keyof AppActions>(
      key: K,
      payload: Parameters<AppActions[K]>[1],
      options?: DispatchOptions
    ): ReturnType<AppActions[K]>
  }

export const store: Module<AppState, RootState> = {
  state,
  getters: appGetters,
  mutations: appMutations,
  actions: appActions,
}
