import { ActionTree, ActionContext } from 'vuex';

import { RootState } from "@/store";
import { AppState } from "./state";
import { AppMutationTypes, AppActionTypes } from "./types";
import { AppMutations } from "./mutations";
import * as API from "@/api/modules/app";

type AugmentedActionContext = {
  commit<K extends keyof AppMutations>(
    key: K,
    payload: Parameters<AppMutations[K]>[1],
  ): ReturnType<AppMutations[K]>
} & Omit<ActionContext<AppState, RootState>, "commit">

type NoAugmentedActionContext = {
  commit<K extends keyof AppMutations>(
    key: K,
  ): ReturnType<AppMutations[K]>
} & Omit<ActionContext<AppState, RootState>, "commit">


export interface AppActions {
  [AppActionTypes.ACTION_REPORT_YEAR_LIST]({ commit }: NoAugmentedActionContext): void
  actReportYearList({ commit }: AugmentedActionContext,): ReturnType<ReportYearListModel>
  actReportDataYear({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataYearModel>[0]): ReturnType<ReportDataYearModel>
  actReportDataYearMon({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataYearMonModel>[0]): ReturnType<ReportDataYearMonModel>
  actReportDataChannel({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataChannelModel>[0]): ReturnType<ReportDataChannelModel>
  actGetDecorationMultipleYear({ commit }: AugmentedActionContext, { ...data }: Parameters<GetDecorationMultipleYearModel>[0]): ReturnType<GetDecorationMultipleYearModel>
  actGetOtherMultipleYear({ commit }: AugmentedActionContext, { ...data }: Parameters<GetOtherMultipleYearModel>[0]): ReturnType<GetOtherMultipleYearModel>
  actGetSurveyStatistics({ commit }: AugmentedActionContext, { ...data }: Parameters<GetSurveyStatisticsModel>[0]): ReturnType<GetSurveyStatisticsModel>
  actReportDataChannelList({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataChannelListModel>[0]): ReturnType<ReportDataChannelListModel>
  actReportDataChannelYearMon({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataChannelYearMonModel>[0]): ReturnType<ReportDataChannelYearMonModel>
  actReportDataList({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataListModel>[0]): ReturnType<ReportDataListModel>
  actReportDataRegionYearMon({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportDataRegionYearMonModel>[0]): ReturnType<ReportDataRegionYearMonModel>
  actWxEnterpriseMessageNirvana({ commit }: AugmentedActionContext, { ...data }: Parameters<WxEnterpriseMessageNirvanaModel>[0]): ReturnType<WxEnterpriseMessageNirvanaModel>
  actGetNirvanaConfig({ commit }: AugmentedActionContext, { ...data }: Parameters<GetNirvanaConfigModel>[0]): ReturnType<GetNirvanaConfigModel>
  actGetNirvanaList({ commit }: AugmentedActionContext, { ...data }: Parameters<GetNirvanaListModel>[0]): ReturnType<GetNirvanaListModel>
  actGetNirvanaNew({ commit }: AugmentedActionContext, { ...data }: Parameters<GetNirvanaNewModel>[0]): ReturnType<GetNirvanaNewModel>
  actGetNirvanaDisparity({ commit }: AugmentedActionContext, { ...data }: Parameters<GetNirvanaDisparityModel>[0]): ReturnType<GetNirvanaDisparityModel>
  actReportShrUserNumber({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportShrUserNumberModel>[0]): ReturnType<ReportShrUserNumberModel>
  actReportShrUserDegree({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportShrUserDegreeModel>[0]): ReturnType<ReportShrUserDegreeModel>
  actReportShrUserDepart({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportShrUserDepartModel>[0]): ReturnType<ReportShrUserDepartModel>
  actReportPersonYearList({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportPersonYearListModel>[0]): ReturnType<ReportPersonYearListModel>
  actReportPersonOrgList({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportPersonOrgListModel>[0]): ReturnType<ReportPersonOrgListModel>
  actReportPersonDataList({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportPersonDataListModel>[0]): ReturnType<ReportPersonDataListModel>
  actReportShrUserYearMon({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportShrUserYearMonModel>[0]): ReturnType<ReportShrUserYearMonModel>
  actReportShrUserDepartMon({ commit }: AugmentedActionContext, { ...data }: Parameters<ReportShrUserDepartMonModel>[0]): ReturnType<ReportShrUserDepartMonModel>
  actActivityOptionList({ commit }: AugmentedActionContext, { ...data }: Parameters<ActivityOptionListModel>[0]): ReturnType<ActivityOptionListModel>
  actActivityDataList({ commit }: AugmentedActionContext, { ...data }: Parameters<ActivityDataListModel>[0]): ReturnType<ActivityDataListModel>
  actCzActivityOptionList({ commit }: AugmentedActionContext, { ...data }: Parameters<ActivityOptionListModel>[0]): ReturnType<ActivityOptionListModel>
  actCzActivityDataList({ commit }: AugmentedActionContext, { ...data }: Parameters<ActivityDataListModel>[0]): ReturnType<ActivityDataListModel>
  actPkTypeList({ commit }: AugmentedActionContext, { ...data }: Parameters<PkTypeListModel>[0]): ReturnType<PkTypeListModel>
}


export const appActions: ActionTree<AppState, RootState> & AppActions = {
  [AppActionTypes.ACTION_REPORT_YEAR_LIST]({ commit }) {
    commit(AppMutationTypes.REPORT_YEAR_LIST)
  },
  actReportYearList({ commit }) {
    return new Promise((resolve, reject) => {
      API.apiReportYearList().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataYear({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataYear(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataYearMon({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataYearMon(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataChannel({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataChannel(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetDecorationMultipleYear({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetDecorationMultipleYear(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetOtherMultipleYear({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetOtherMultipleYear(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetSurveyStatistics({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetSurveyStatistics(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataChannelList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataChannelList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataChannelYearMon({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataChannelYearMon(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportDataRegionYearMon({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportDataRegionYearMon(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actWxEnterpriseMessageNirvana({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiWxEnterpriseMessageNirvana(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetNirvanaConfig({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetNirvanaConfig(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetNirvanaList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetNirvanaList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetNirvanaNew({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetNirvanaNew(data).then(res => {
        return resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },
  actGetNirvanaDisparity({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiGetNirvanaDisparity(data).then(res => {
        return resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportShrUserNumber({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportShrUserNumber(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportShrUserDegree({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportShrUserDegree(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportShrUserDepart({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportShrUserDepart(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportPersonYearList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportPersonYearList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportPersonOrgList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportPersonOrgList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportPersonDataList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportPersonDataList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportShrUserYearMon({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportShrUserYearMon(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actReportShrUserDepartMon({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiReportShrUserDepartMon(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actActivityOptionList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiActivityOptionList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actActivityDataList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiActivityDataList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actCzActivityOptionList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiCzActivityOptionList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actCzActivityDataList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiCzActivityDataList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  actPkTypeList({ commit }, { ...data }) {
    return new Promise((resolve, reject) => {
      API.apiPkTypeList(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
