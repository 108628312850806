import { RouteRecordRaw } from "vue-router"

export const pcRoutes: RouteRecordRaw[] = [
  {
    path: "/pc",
    name: "pc",
    component: () => import("../pages/pc/index.vue"),
    redirect: "/pc/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../pages/pc/home.vue"),
      }
    ]
  },
]

export default pcRoutes;