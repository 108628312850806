import axios, { AxiosRequestConfig } from "axios";
import { baseURL } from "@/config/env";
import { Toast } from 'vant';
import { funcUrlDel } from "@/utils/app";
import { API_ROUTER_CONFIG } from "@/api/config";

const API_CONFIG = {
  baseURL: baseURL
}
export const localStorageKey = ["dept_id", "userid", "username", "bi_access_token", "shr_auth_real_id", "shr_dept_id", "bimar_group_id"];

const instance = axios.create()
export const ajax = <T = any>(opt: AxiosRequestConfig): Promise<T> => {
  if (opt.url === API_ROUTER_CONFIG.wxEnterpriseMessageReport || opt.url === API_ROUTER_CONFIG.wxEnterpriseOauthUrlReport) {

  } else if (!window.localStorage.getItem(localStorageKey[3])) {
    return new Promise((resolve, reject) => {
      const res = { status: 200 } as any;
      resolve(res)
    })
  } else if (opt.method === 'post') {
    opt.data = opt.data ? opt.data : {};
    localStorageKey.forEach(el => {
      opt.data[el] = window.localStorage.getItem(el);
    });
  }

  return new Promise((resolve, reject) => {
    let ajaxOpt = Object.assign({}, API_CONFIG, opt);
    instance.request<T>(ajaxOpt)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            resolve(res.data)
          } else if (res.data.status === 70102) {
            Toast(res.data.msg);
            // 登录过期
            window.localStorage.clear();
            window.location.href = funcUrlDel("code");
          } else if (res.data.status) {
            Toast(res.data.msg);
            // console.log(opt);
            // alert(JSON.stringify(opt));
            reject(res.data)
          }
        } else {
          Toast('服务器报错' + res.status);
          reject(res.data)
        }
      })
      .catch(err => reject({
        status: -1,
        msg: err.message,
        data_list: ""
      }))
  })
}
