import { RouteRecordRaw } from "vue-router"

const mobileRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("../pages/index.vue"),
    meta: {
      app: "index",
    }
  },
  {
    path: "/wechatReport",
    name: "wechatReport",
    component: () => import("../pages/wechatReport/index.vue"),
    meta: {
      app: "wechat_report",
    },
    redirect: "/wechatReport/AIData",
    children: [
      {
        path: "AIData",
        name: "AIData",
        component: () => import("../pages/wechatReport/AIData.vue"),
        meta: {
          app: "wechat_report",
          title: "居众集团报表系统",
        },
      },
      {
        path: "area",
        name: "area",
        component: () => import("../pages/wechatReport/area.vue"),
        meta: {
          app: "wechat_report",
          title: "居众集团报表系统",
        },
      },
      {
        path: "source",
        name: "source",
        component: () => import("../pages/wechatReport/source.vue"),
        meta: {
          app: "wechat_report",
          title: "居众集团报表系统",
        },
      },
      {
        path: "resourceDetails",
        name: "resourceDetails",
        component: () => import("../pages/wechatReport/resourceDetails.vue"),
        meta: {
          app: "wechat_report",
          title: "居众集团报表系统",
        },
      },
    ]
  },
  {
    path: "/wechatNirvana",
    name: "wechatNirvana",
    component: () => import("../pages/wechatNirvana/index.vue"),
    meta: {
      app: "wechat_nirvana",
      title: "居众集团PK榜单"
    }
  },
  {
    path: "/compare",
    name: "compare",
    component: () => import("../pages/wechatNirvana/views/compare.vue"),
    meta: {
      app: "wechat_nirvana",
      title: "居众集团PK榜单"
    }
  },
  {
    path: "/wechatReportPerson",
    name: "wechatReportPerson",
    component: () => import("../pages/wechatReportPerson/index.vue"),
    meta: {
      app: "wechat_report_person",
      title: "居众集团个人数据看板"
    }
  },
  {
    path: "/personDataList",
    name: "personDataList",
    component: () => import("../pages/wechatReportPerson/personDataList.vue"),
    meta: {
      app: "wechat_report_person",
      title: "居众集团个人数据看板"
    }
  },
  {
    path: "/wechatActivity",
    name: "wechatActivity",
    component: () => import("../pages/wechatActivity/index.vue"),
    meta: {
      app: "wechat_activity",
      title: "居众琅琊榜-新",
    }
  },
  {
    path: "/weekReceived",
    name: "weekReceived",
    component: () => import("../pages/wechatActivity/views/weekReceived.vue"),
    meta: {
      app: "wechat_activity",
      title: "居众琅琊榜-新",
    }
  },
  {
    path: "/weekAmount",
    name: "weekAmount",
    component: () => import("../pages/wechatActivity/views/weekAmount.vue"),
    meta: {
      app: "wechat_activity",
      title: "居众琅琊榜-新",
    }
  },
  {
    path: "/rankingSeason",
    name: "rankingSeason",
    component: () => import("../pages/wechatActivity/views/rankingSeason.vue"),
    meta: {
      app: "wechat_activity",
      title: "居众琅琊榜-新",
    }
  },
  {
    path: "/rankingDesign",
    name: "rankingDesign",
    component: () => import("../pages/wechatActivity/views/rankingDesign.vue"),
    meta: {
      app: "wechat_activity",
      title: "居众琅琊榜-新",
    }
  },
  {
    path: "/wechatCzActivity",
    name: "wechatCzActivity",
    component: () => import("../pages/wechatCzActivity/index.vue"),
    meta: {
      app: "wechat_cz_activity",
      title: "居众琅琊榜-常株",
    }
  },
  {
    path: "/weekCzReceived",
    name: "weekCzReceived",
    component: () => import("../pages/wechatCzActivity/views/weekReceived.vue"),
    meta: {
      app: "wechat_cz_activity",
      title: "居众琅琊榜-常株",
    }
  },
  {
    path: "/weekCzAmount",
    name: "weekCzAmount",
    component: () => import("../pages/wechatCzActivity/views/weekAmount.vue"),
    meta: {
      app: "wechat_cz_activity",
      title: "居众琅琊榜-常株",
    }
  },
  {
    path: "/rankingCzSeason",
    name: "rankingCzSeason",
    component: () => import("../pages/wechatCzActivity/views/rankingSeason.vue"),
    meta: {
      app: "wechat_cz_activity",
      title: "居众琅琊榜-常株",
    }
  },
  {
    path: "/rankingCzDesign",
    name: "rankingCzDesign",
    component: () => import("../pages/wechatCzActivity/views/rankingDesign.vue"),
    meta: {
      app: "wechat_cz_activity",
      title: "居众琅琊榜-常株",
    }
  },
  {
    path: "/wechatPk",
    name: "wechatPk",
    component: () => import("../pages/wechatPk/index.vue"),
    meta: {
      app: "wechat_pk",
      title: "居众集团PK榜单"
    }
  },
  {
    path: "/pkCompare",
    name: "pkCompare",
    component: () => import("../pages/wechatPk/views/compare.vue"),
    meta: {
      app: "wechat_pk",
      title: "居众集团PK榜单"
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../pages/demo/test.vue")
  },
  {
    path: "/clear",
    name: "clear",
    component: () => import("../pages/demo/clear.vue")
  },
  {
    path: "/noPermission",
    name: "noPermission",
    component: () => import("../pages/errorPage/noPermission.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../pages/errorPage/404.vue")
  },
  {
    path: "/502",
    name: "502",
    component: () => import("../pages/errorPage/502.vue")
  },
]

export default mobileRoutes