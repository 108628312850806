export interface AppState extends Identity {
  timeout: number; // 超时时间
  stamp: number; // 当前时间戳
  reportYearList: (string | number)[]
  reportConfig?: ReportConfig
}

export const state: AppState = {
  userid: "",
  dept_id: "",
  username: "",
  timeout: 86400,
  stamp: parseInt(Date.parse(new Date() + "") / 1000 + ""),
  reportYearList: [],
  reportConfig: {}
}