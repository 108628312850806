import { createStore } from 'vuex'

import { store as app, AppStore, AppState } from "./modules/app";

export interface RootState {
  app: AppState
}

export type Store = AppStore<Pick<RootState, 'app'>>

const plugin = (store: Store): void => {
  const stamp = parseInt(window.localStorage.getItem("stamp") || "0");
  if (stamp < store.state.app.stamp) {
    window.localStorage.clear();
    window.localStorage.setItem("stamp", (store.state.app.stamp + store.state.app.stamp) + "")
  } else {
    store.state.app.dept_id = window.localStorage.getItem("dept_id") || ""
    store.state.app.userid = window.localStorage.getItem("userid") || ""
    store.state.app.username = window.localStorage.getItem("username") || ""
  }
}

export default createStore({
  plugins: [plugin],
  modules: {
    app
  },
})
