import { ISPROD } from "@/config/env";
import { testInfo, appsRedirectUri } from "@/config/default";
import { ajax, localStorageKey } from '@/utils/ajax';
import { API_ROUTER_CONFIG } from "@/api/config";
import { Toast } from 'vant';

export const hasIdentity = (): boolean => {
  if (!ISPROD) {
    localStorageKey.forEach(el => {
      window.localStorage.setItem(el, testInfo[el] + "");
    });

    return true
  }
  const dept_id = window.localStorage.getItem("dept_id");
  const userid = window.localStorage.getItem("userid");
  if (!dept_id || !userid || dept_id == "0") {
    return false
  }
  return true
}

export const getParams = function () {
  let obj: { [key: string]: string } = {}
  window.location.search.replace('?', '').split('&').forEach(item => {
    let res = item.split('=');
    obj[res[0]] = res[1]
  })
  return obj
}

export const init = (to: any) => {
  return new Promise((resolve, reject) => {
    if (!hasIdentity()) {
      let params = getParams();
      const app = to.meta.app as (keyof AppsRedirectUri);
      if (params.code) {
        ajax({
          url: API_ROUTER_CONFIG.wxEnterpriseMessageReport,
          data: {
            type: app,
            code: params.code
          },
          method: "post",
        })
          .then((res: any) => {
            if (res.status === 200) {
              window.localStorage.setItem('dept_id', res.data_list.main_department + "");
              window.localStorage.setItem('userid', res.data_list.userid + "");
              window.localStorage.setItem('username', res.data_list.name);

              window.localStorage.setItem('bi_access_token', res.data_list.bi_access_token + "");
              window.localStorage.setItem('shr_auth_real_id', res.data_list.shr_auth_real_id + "");
              window.localStorage.setItem('shr_dept_id', res.data_list.dept_id + "");
              window.localStorage.setItem('bimar_group_id', res.data_list.bimar_group_id + "");
              window.location.reload();
            } else {
              // 重新获取code
              setTimeout(() => {
                alert("重新获取code");
                window.localStorage.clear();
                window.location.href = funcUrlDel("code");
              }, 2000);
            }
          })
          .catch(err => {
          })
      } else if (appsRedirectUri[app]) {
        ajax({
          url: API_ROUTER_CONFIG.wxEnterpriseOauthUrlReport,
          data: {
            redirect_uri: appsRedirectUri[app],
            type: app
          },
          method: "post",
        }).then(res => {
          if (res.status == 200) {
            // console.log(res.data_list.oauth_url);
            window.location.href = res.data_list.oauth_url;
            resolve({ status: 200 });
            // window.location.href = 'http://192.168.3.118:8082/wechatReportPerson?code=mt0Ec6dgJwfIU5Ma55gwvSdiXpFR6Ptrht02LuURy1Q';
          }
        }).catch(err => {
          Toast("微信重定向失败");
          reject({ status: -1 });
        })
      } else {
        resolve({ status: 200 });

      }
    } else {
      resolve({ status: 200 });
    }
  })
}
// 删除url中某个参数
export const funcUrlDel = function (name: any) {
  var loca = window.location;
  var baseUrl = loca.origin + loca.pathname + "?";
  var query = loca.search.substr(1);
  if (query.indexOf(name) > -1) {
    var obj: any = {}
    var arr: any = query.split("&");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split("=");
      obj[arr[i][0]] = arr[i][1];
    };
    delete obj[name];
    var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
    return url;
  };
  return '';
}
export const deepObjectMerge = function (FirstOBJ: any, SecondOBJ: any) {
  // 深度合并对象
  for (var key in SecondOBJ) {
    FirstOBJ[key] =
      FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
        ? deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
        : (FirstOBJ[key] = SecondOBJ[key]);
  }
  return FirstOBJ;
}

export const deepObjectMerges = function (arr: unknown[]) {
  let tem = {}
  for (let i = 0; i < arr.length; i++) {
    tem = deepObjectMerge(tem, arr[i])
  }
  return tem
}

